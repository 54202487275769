// client/src/components/Login.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ setUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [message, setMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/login`, { email, password }, { withCredentials: true });
      setUser(res.data.user);
      navigate('/');
    } catch (err) {
      setMessage(err.response?.data?.message || 'Login failed.');
    }
  };

  useEffect(() => {
    document.title = "Zahleninsel - Login"; // Set page title when this component is mounted
  }, []); // Empty dependency array ensures this runs only once when component mounts

  return (
    <div>
      {/* <h2>Login</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handleLogin}>
        <div>
          <label>Email:</label>
          <input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} required />
        </div>
        <div>
          <label>Password:</label>
          <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} required />
        </div>
        <button type="submit">Login</button>
      </form>
      <p>Or</p>
      <a href={`${process.env.REACT_APP_SERVER_URL}/auth/google`}>Login with Google</a> */}
      <div className="tmp" style={{ backgroundColor: "#ffe7ac" }}>
        <div style={{ maxWidth: "1000px", margin: "auto" }}>
          <h1 style={{ marginTop: "60px", fontFamily: "Arial, Helvetica, sans-serif" }}> Der Login ist derzeit deaktiviert.</h1>
          <p style={{ fontFamily: "Arial, Helvetica, sans-serif", fontSize: "20px" }}>Besuche uns gerne zu einem späteren Zeitpunkt nochmal</p>
        </div>
      </div>
    </div>
  );
};

export default Login;