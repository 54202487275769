import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe with your publishable key
//const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Products = ({ user }) => {
  const [purchases, setPurchases] = useState([]);
  const navigate = useNavigate();

  const products = [
    { id: 'product1', name: 'Product 1', priceId: '' }, // price_xxxx
    { id: 'product2', name: 'Product 2', priceId: '' }
  ];

  useEffect(() => {
    const fetchPurchases = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/purchases`, { withCredentials: true });
        setPurchases(res.data);
      } catch (err) {
        console.error('Error fetching purchases:', err);
      }
    };

    fetchPurchases();
  }, []);

  const handleBuy = async (product) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/stripe/create-checkout-session`, {
        priceId: product.priceId,
        product: product.id
      }, { withCredentials: true });

      const sessionId = res.data.sessionId;
      const stripe = await stripePromise;

      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error('Error redirecting to Stripe Checkout:', error);
        alert('Error redirecting to Stripe Checkout.');
      }
    } catch (err) {
      console.error('Error creating checkout session:', err);
      alert('Error creating checkout session.');
    }
  };

  const handleOpen = (product) => {
    // Logic to open the product or navigate to the product page
    //navigate(`/purchases/${product.id}`);
    window.location.href = `/purchases/${product.id}`;
  };

  const hasPurchased = (productId) => {
    return purchases.some(purchase => purchase.product === productId && purchase.status === 'completed');
  };

  useEffect(() => {
    document.title = "Zahleninsel - Bestellen"; // Set page title when this component is mounted
  }, []); // Empty dependency array ensures this runs only once when component mounts

  return (
    <div className="tmp" style={{backgroundColor: "#ffe7ac"}}>
      {/* <h2>Products</h2>
      {products.map(prod => (
        <div key={prod.id}>
          <h3>{prod.name}</h3>
          {hasPurchased(prod.id) ? (
            <button onClick={() => handleOpen(prod)}>Open</button>
          ) : (
            <button onClick={() => handleBuy(prod)}>Buy</button>
          )}
        </div>
      ))} */}
      <div style={{maxWidth: "1000px", margin: "auto"}}>
        <h1 style={{marginTop: "60px", fontFamily: "Arial, Helvetica, sans-serif"}}> Die <i>Zahleninselapp</i> befindet sich noch in der Entwicklung.</h1>
        <p style={{fontFamily: "Arial, Helvetica, sans-serif", fontSize: "20px"}}>Besuche uns gerne zu einem späteren Zeitpunkt nochmal</p>
      </div>
    </div>
  );
};

export default Products;