import React, { useEffect } from 'react';
import '../assets/styles.css';

const Kontakt = ({ user }) => {
    useEffect(() => {
        document.title = "Zahleninsel - Kontakt"; // Set page title when this component is mounted
    }, []); // Empty dependency array ensures this runs only once when component mounts

    return (<>
        <div className="tmp" style={{ backgroundColor: "#ffe7ac" }}>
            <div style={{ maxWidth: "1000px", margin: "auto" }}>
                <h1 style={{ marginTop: "60px", fontFamily: "Arial, Helvetica, sans-serif" }}> Kontakt</h1>
                <p style={{ fontFamily: "Arial, Helvetica, sans-serif", fontSize: "20px" }}>Allgemeine Fragen: support@zahleninsel.de<br/>Fragen zur Rechnung: buchhaltung@zahleninsel.de</p>
                <h1 style={{ marginTop: "60px", fontFamily: "Arial, Helvetica, sans-serif" }}> Die Autorin</h1>
                <p style={{ fontFamily: "Arial, Helvetica, sans-serif", fontSize: "20px" }}>Die Idee eines Computerprogramms entstand während der Förderung von Kindern mit massiven Schwierigkeiten beim Rechnen Lernen (Dyskalkulie). Die Autorin leitete mehrere Jahre lang eine Beratungsstelle für Dyskalkulie und hielt zahlreiche Fortbildungen für Lehrerinnen und Lehrer, Erzieherinnen und Erzieher sowie Eltern zu diesem Thema und zu seiner Prävention. Außerdem entwickelte sie gemeinsam mit einer ganz besonderen Therapeutin, bei der sie als Studentin bereits wichtige Erfahrungen sammeln durfte, Anleitungshefte für Eltern und Therapeutinnen und Therapeuten. Sie ist seit fast 25 Jahren als Sonderschullehrerin tätig, zunächst in einem Sonderpädagogischen Bildungs- und Beratungszentrum. Seit mehr als 16 Jahren arbeitet sie mit großer Überzeugung in inklusiven Settings an Grundschulen, wo sie auch ihre Ausbildung als Montessoripädagogin mit einfließen lässt.</p>
            </div>
        </div>
    </>)
}

export default Kontakt;
