import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';

import ScrollToTop from './ScrollToTop.jsx';
import Home from './Home';
import Insel from './Insel';
import Kontakt from './Kontakt';
import Login from './Login';
import Products from './Products';
import Checkout from './Checkout';
import Success from './Success';
import Fail from './Fail';
import ProtectedPage from './ProtectedPage';
import ProtectedRoute from './ProtectedRoute.jsx';

import '../assets/styles.css';
import { Link } from 'react-router-dom';

export function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch current user on mount
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}/auth/user`, { withCredentials: true })
      .then(res => {
        setUser(res.data.user);
        setLoading(false);
      })
      .catch(() => {
        setUser(null);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Router>
        <div className='nav'>
          <div className="nav-links">
            <Link to="/">Willkommen</Link>
            <Link to="/insel">Die Insel</Link>
            <Link to="/products">Bestellen</Link>
            <Link to="/kontakt">Kontakt</Link>
          </div>

          {user ? (
            <div className="user-section">
              <span>Hallo, {user.email}</span>
              <a href="/auth/logout">Logout</a>
            </div>
          ) : (
            <Link to="/login">Login</Link>
          )}
        </div>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home user={user} />} />
          <Route path="/insel" element={<Insel user={user} />} />
          <Route path="/kontakt" element={<Kontakt user={user} />} />

          <Route path="/login" element={<Login setUser={setUser} />} />
          <Route path="/products" element={<Products user={user} />} />
          <Route path="/checkout" element={<Checkout user={user} />} />
          <Route path="/success" element={<Success />} />
          <Route path="/fail" element={<Fail />} />
          <Route
            path="/protected"
            element={
              <ProtectedRoute user={user} loading={loading}>
                <ProtectedPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
}