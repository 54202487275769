import React from 'react';
import { Navigate } from 'react-router-dom';

// This component checks if the user is authenticated
const ProtectedRoute = ({ user, loading, children }) => {
  if (loading) {
    return <div>Loading protected content...</div>; // Show loading while checking user status
  }

  return user ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;