// client/src/components/Checkout.jsx

import React from 'react';

const Checkout = () => {
  return (
    <div>
      <h2>Processing Checkout...</h2>
    </div>
  );
};

export default Checkout;