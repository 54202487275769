// client/src/components/Fail.jsx

import React from 'react';
import { Link } from 'react-router-dom';

const Fail = () => {
  return (
    <div>
      <h2>Purchase Failed</h2>
      <p>Your purchase was not completed.</p>
      <Link to="/products">Try Again</Link>
    </div>
  );
};

export default Fail;