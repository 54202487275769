// client/src/components/Success.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';

const Success = () => {
  const location = useLocation();
  const [message, setMessage] = useState('Processing your purchase...');

  useEffect(() => {
    const fetchSession = async () => {
      const params = new URLSearchParams(location.search);
      const sessionId = params.get('session_id');
      if (sessionId) {
        try {
          const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/stripe/session-status`, {
            params: { session_id: sessionId }
          });
          console.log(res.data);
          if (res.data.status === 'complete' || res.data.status === 'paid') {
            setMessage('Purchase successful!');
          } else {
            setMessage('Purchase not completed.');
          }
        } catch (err) {
          setMessage('Error verifying purchase.');
        }
      } else {
        setMessage('No session ID provided.');
      }
    };
    fetchSession();
  }, [location.search]);

  return (
    <div>
      <h2>Success</h2>
      <p>{message}</p>
      <Link to="/">Go Home</Link>
    </div>
  );
};

export default Success;