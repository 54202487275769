// client/src/components/Home.jsx

import React, { useEffect } from 'react';
import bg from '../assets/bg.svg';
import fg from '../assets/fg.svg';


import '../assets/styles.css';

const Home = ({ user }) => {
  useEffect(() => {
    document.title = "Zahleninsel Willkommen"; // Set page title when this component is mounted
  }, []); // Empty dependency array ensures this runs only once when component mounts

  return (
    <div>
      <div className='image-container'>
        <img src={bg} alt="Hintergrund" className='bg' />
        <img src={fg} alt="Vordergrund" className='fg' />
        <p>Mit Hilfe strukturierter Zahlenbilder den Zahlenraum 20 erobern</p>
      </div>
      <div className='footer'>
        <div className='content'>
          <h1>Herzlich Willkommen auf der Zahleninsel!</h1>
          <p>Ziel der <b><i>Zahleninselapp</i></b> ist es, Ihre Kinder, Ihre Schülerinnen und Schüler beim Meistern des <b>ersten großen Ziels</b> im Mathematikunterricht, <b>die Automatisierung des Zahlenaums 10 bzw. 20</b>, zu unterstützen.</p>
          <h1 style={{marginTop:"60px"}}>Rechnen lernen mithilfe strukturierter Mengenbilder</h1>
          <p>In der <b><i>Zahleninselapp</i></b> werden als Starthilfe in die Welt der Zahlen bewusst <b>strukturierte Mengendarstellungen</b> als wesentlicher Bestandteil des Gesamtkonzepts gewählt und durchgehend verwendet. Die lineare Darstellung, die oft angeboten wird, stellt für viele Kinder häufiger am Anfang des Mathematikunterrichts eine große Herausforderung da. Die hier genutzten strukturierten Mengenbilder hingegen bedeuten eine Orientierungshilfe, für manche Kinder sogar eine Art „Rettungsanker“, mit deren Hilfe sich die Kinder von der fehlerhaften „Strategie“ des zählenden Rechnens lösen können. Nach dieser Starthilfe ist es dann später kein Problem mehr, auch die lineare Darstellung zu nutzen, da die Kinder eine für sie hilfreiche Struktur verinnerlicht haben und die lineare Darstellung (wie z.B. beim Hunderterbrett) strukturierend erfassen können.</p>
          <p><b>Diese Mengendarstellungen...</b></p>
          <ul>
            <li>knüpfen an bekanntes Wissen Ihres Kindes, Ihrer Schülerinnen und Schüler an (Würfelbilder bis zur Menge Fünf)</li>
            <li>berücksichtigen dabei die wichtige Fünferstruktur</li>
            <li>sind quasi-simultan erfassbar</li>
            <li>machen Strategien, Strukturen und Analogien sichtbar</li>
            <li>ermöglichen, Zahlen als Zusammensetzungen aus anderen Zahlen zu begreifen</li>
            <li>sind auch auf den hohen Zahlenraum übertragbar</li>
            <li>ermöglichen als visuelle Stütze eine Entlastung des Arbeitsgedächtnisses</li>
          </ul>
          <p>Ziel ist schließlich, dass Ihr Kind den Zahlenraum 10 bzw. 20 automatisiert, d.h. spontan die Lösungen aus seinem Gedächtnis abrufen kann und dazu eine Mengen- und Handlungsvorstellung zu den Zahlen und Rechenoperationen entwickelt hat. Das Training zur Automatisierung wird deshalb ebenfalls auf der App berücksichtigt.</p>
          <p>Für die Entwicklung eines mathematischen Grundverständnisses Ihrer Kinder ist es grundsätzlich wichtig, ihnen Übungsmöglichkeiten auf allen drei Repräsentationsebenen zu ermöglichen:</p>
          <ol>
            <li>enaktive Repräsentationsebene (konkrete Handlungserfahrungen, Handeln mit didaktischem Material)</li>
            <li>ikonische Repräsentation</li>
            <li>symbolische Ebene, in Form von geschriebenen Zahlen und Rechenzeichen</li>
          </ol>
          <p>Die <b><i>Zahleninselapp</i></b> legt einen großen Schwerpunkt auf die ikonische Ebene. Diese stellt eine Art Brücke zwischen dem konkreten Umgang mit Material und dem Rechnen auf der rein abstrakten Ebene. Die enthaltenen Übungen mit den konstanten und strukturierten Mengenbildern sollen Ihren Kindern dabei helfen, sich allmählich von der äußeren Anschauung (sichtbare, „greifbare“ Mengenbilder) zu lösen und mit „inneren Bildern“ von Zahlen rechnen zu lernen.</p>
          <p>Da die Entwicklung von Vorstellungsbildern zu den Zahlen nicht nur visuell, sondern grundsätzlich über alle Sinne geschieht, sollten Ihre Kinder, Ihre Schülerinnen und Schüler grundsätzlich über möglichst viele Sinneskanäle (z.B. Tasten, Bewegung, Klang) die Zahlen kennenlernen.</p>
          <p>Die Inhalte der <b><i>Zahleninsel</i></b> wurden über einen sehr langen Zeitraum als Computerprogramm (ursprünglich von Herrn Sören Grüßinger, Sutt Multimedia umgesetzt) genutzt, teilweise verändert oder ergänzt. In den vergangenen Jahren zeigte sich, dass sie eine hohe Motivation für die Kinder bedeuten. Deshalb stellen sie, sofern die App in Maßen eingesetzt wird, ein sehr hilfreiches Medium zum Trainieren der Rechengrundfertigkeiten da.</p>
          
          <p style={{textAlign:"center", fontSize: "12px", marginTop: "50px"}}>© Zahleninsel.de 2024 - Heute</p>
        </div>
      </div>

    </div>
  );
};

export default Home;