import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProtectedPage = () => {
  const [content, setContent] = useState('Loading...');

  useEffect(() => {
    // Replace 'product1' with the actual product ID required
    axios.get(`${process.env.REACT_APP_SERVER_URL}/purchases/product/product1`, { withCredentials: true })
      .then(res => {
        if (res.data.owns) {
          setContent('This is a protected page. You have access because you bought the product.');
        } else {
          setContent('You do not have access to this page.');
        }
      })
      .catch(() => {
        setContent('Error fetching purchase information.');
      });
  }, []);

  return (
    <div>
      <h2>Protected Page</h2>
      <p>{content}</p>
    </div>
  );
};

export default ProtectedPage;