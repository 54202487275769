import React, { useEffect } from 'react';
import '../assets/styles.css';

const Insel = ({ user }) => {
    useEffect(() => {
        document.title = "Zahleninsel - Die Insel"; // Set page title when this component is mounted
    }, []); // Empty dependency array ensures this runs only once when component mounts

    return (<>
        <div className='footer' style={{minHeight: "100vh", backgroundColor: "#ffe7ac"}}>
            <div className='content'>
                <h1 style={{marginTop:"60px"}}>Die Insel</h1>
                <p>Die <b><i>Zahleninselapp</i></b> ist geeignet für die Förderung und Begleitung von:</p>
                <ul>
                    <li>Vorschulkindern im letzten Kindergartenjahr mit Interesse für Mathematik</li>
                    <li>Kindern der ersten Klasse der Grundschule</li>
                    <li>Kindern an sonderpädagogischen Bildungs- und Beratungszentren</li>
                    <li>Kindern aller Schularten mit massiven Schwierigkeiten beim Rechnen Lernen</li>
                </ul>
                <p><b>Die einzelnen Übungen</b></p>
                <p>Die einzelnen Übungen sind systematisch aufeinander aufgebaut. Deshalb macht es Sinn, mit Übung 1 zu starten. In fast jedem „Bullauge“ des Zahlenschiffs sind zwei verschiedene Übungen enthalten.</p>
                <p>Bei einigen Kindern werden gelegentlich die Übung 2 und 3 miteinander getauscht, wenn Kindern zunächst die Zahlzerlegung noch schwer fällt. Wenn Sie also feststellen, dass die Übung 2 für Ihr Kind noch eine große Herausforderung bedeutet, können Sie auch die Übung 3 voranstellen. Folgende Übungen sind enthalten:</p>
                <h1 style={{marginTop:"60px"}}>Zahlenraum 10 (erstes Schiff)</h1>
                <ol>
                    <li>Übungen zur Mengenerfassung der strukturierten Darstellung (sowie Gedächtnis)</li>
                    <li>Übungen zur Zahlzerlegung und zur Zahlergänzung: Zahlen als Mengen verstehen, die Zusammensetzungen aus kleineren Mengen sind</li>
                    <li>Additions- und Subtraktionsaufgaben</li>
                    <li>Rund um die Zehn- Zehnerergänzung, Passerzahlen, Zehnerfreunde</li>
                    <li>Platzhalteraufgaben</li>
                    <li>„Schummel“-Aufgaben: Fehler entdecken und Herrn Schummel fressen lassen</li>
                    <li>Training zur Automatisierung der Aufgaben im Zahlenraum 10 (mit und ohne visuelle Hilfe der Mengenbilder)</li>
                    <li>Übungen zur Darstellung mit den Zehnerstangen und Einern, Mengen mit unterschiedlichen Darstellungen erkennen</li>
                </ol>
                <h1 style={{marginTop:"60px"}}>Zahlenraum 20 (zweites Schiff)</h1>
                <p>Im zweiten Schiff zur Eroberung des Zahlenraums 20 werden in den einzelnen „Bullaugen“ verschiedene Strategien thematisiert. Hier können Sie, je nach Einführung der einzelnen Strategie, flexibler bei der Wahl der Übung vorgehen. Wichtig ist, dass Ihr Kind die Zehnerergänzung (auch Passerzahlen, verliebte Zahlen, Zehnerfreunde genannt) verinnerlicht hat.</p>
                <ol>
                    <li>Übungen zur Mengenerfassung im Zahlenraum 20 (Würfelbilder in Kombination mit den Zehnerstangen)</li>
                    <li>„Einfache Aufgaben“: Analogien entdecken</li>
                    <li>„Schritt-für Schritt“-Aufgaben: bis zum vollen Zehner ergänzen und dann weiter</li>
                    <li>Mengenvergleiche, Größenunterschiede (Differenz) zweier Zahlen zum Rechnen nutzen lernen</li>
                    <li>Fünfertrick- das ist der Hit!</li>
                    <li>Neunertrick</li>
                    <li>Verdoppeln und Halbieren als Übungen</li>
                    <li>Übungen zum bewussten Umgang mit den Strategien (sich seiner bevorzugten Strategie bewusst werden)</li>
                    <li>„Schummel“-Aufgaben: Fehler entdecken und Herrn Schummel fressen lassen</li>
                    <li>Platzhalteraufgaben</li>
                    <li>Training zur Automatisierung der Aufgaben bis 20 (mit und ohne visuelle Hilfe der Mengenbilder)</li>
                </ol>
                <h1 style={{marginTop:"60px"}}>Einbettung der Übungen in das Piratenthema</h1>
                <p>Ihre Kinder werden in diesem Programm von einem Piratenjungen begleitet, der die einzelnen Übungen und „Tricks“ erklärt und Ihren Kindern die Rückmeldung gibt, ob die Aufgabe korrekt gelöst wurde. Nach jeder abgeschlossenen Übungseinheit erhalten Ihre Kinder einen Edelstein in ihre imaginäre Schatzkiste.  Bei einigen Kindern ist es eventuell hilfreich, diese imaginären Belohnungen in „echte“ zu „verwandeln“.</p>
                <h1 style={{marginTop:"60px"}}>Los geht’s!</h1>
                <p>Nachdem Ihre Kinder vom kleinen Piraten begrüßt wurden, tragen sie ihren Namen ein. Somit können sie dann bei vollständiger korrekter Bearbeitung eines Aufgabenbereichs Edelsteine für ihre individuelle Schatzkiste sammeln. Es ist aber auch möglich, eine ganze Klasse anzulegen, damit alle gemeinsam die Edelsteine sammeln können, oder kleine Gruppen. Ist ein „Bullauge“ bereits geöffnet und leer, bedeutet dies, dass dieser Übungsbereich bereits vollständig korrekt bearbeitet wurde. Dennoch können die Übungen nochmals wiederholt werden.</p>
            
                <p style={{textAlign:"center", fontSize: "12px", marginTop: "50px"}}>© Zahleninsel.de 2024 - Heute</p>
            </div>
        </div>
    </>)
}

export default Insel;
